import { _getCookie, _setCookie } from "./cookie";

const shouldShowLogin = function() {
    if(_getCookie('dc_timestamp')) {
        return false;
    } else {
        return true;
    }
}

if (shouldShowLogin() && !window.location.hash) {
    window.location.href = "index.html#login";
}

if (!shouldShowLogin() && (window.location.href.indexOf('index') !== -1 || window.location.href.charAt(window.location.href.length - 1) == '/')) {
    window.location.href = "home.html";
}

let params = (new URL(window.location)).searchParams;
let dcTimestamp = params.get('dc_timestamp');
let deeplink = params.get('deeplink');

if (dcTimestamp && dcTimestamp.length === 10) {
    var cookieLifetime = 7; // Cookie expiry in days
    _setCookie('doccheck', true, cookieLifetime);
    _setCookie('dc_timestamp', dcTimestamp, cookieLifetime);    
    // _setCookie('hcmbmsch', 1, cookieLifetime);    
    window.location.href = "home.html";
}

if (deeplink && deeplink.length === 32) {
    var cookieLifetime = 7;
    let dcTimestamp = Math.floor(Date.now() / 1000);
    _setCookie('doccheck', true, cookieLifetime);
    _setCookie('dc_timestamp', dcTimestamp, cookieLifetime);
    window.location.href = "home.html";
}
