import { _getCookie, _setCookie } from "./cookie";
var activeModal

if (window.NodeList && !NodeList.prototype.forEach) {
	NodeList.prototype.forEach = Array.prototype.forEach;
}

document.querySelectorAll('[data-toggle=modal]').forEach(function (trigger) {
  trigger.addEventListener('click', function (event) {
    if (this.dataset.target) {
      event.preventDefault()
      toggleModal(this.dataset.target)
    }
  })
})

document.querySelectorAll('[data-dismiss=modal]').forEach(function (trigger) {
  trigger.addEventListener('click', function (event) {
    if (this.dataset.target) {
      event.preventDefault()
      dismissModal(this.dataset.target)
    }
  })
})

document.querySelectorAll('[data-link]').forEach(function (trigger) {
  trigger.addEventListener('click', function (event) {
    if (this.dataset.target) {
      event.preventDefault()
      const linkOut = trigger.getAttribute('data-link');
      updateLinkOut(this.dataset.target, linkOut)
    }
  })
})

document.querySelectorAll('.continue-button').forEach(function (trigger) {
  trigger.addEventListener('click', function (event) {
    event.preventDefault();
    dismissModal(this.dataset.target, this.href)
  })
})

document.onkeydown = function (evt) {
  evt = evt || window.event
  var isEscape = false
  if ('key' in evt) {
    isEscape = (evt.key === 'Escape' || evt.key === 'Esc')
  } else {
    isEscape = (evt.keyCode === 27)
  }
  if (isEscape && activeModal) {
    dismissModal(activeModal)
  }
}

function dismissModal (target, linkOut) {
  const modal = document.querySelector(target)
  modal.classList.add('opacity-0')
  modal.classList.add('pointer-events-none')
  document.body.classList.remove('modal-active')
  activeModal = null
  if(linkOut){
    window.open(linkOut, '_blank');
  }
}

function toggleModal (target) {
  const modal = document.querySelector(target)
  modal.classList.toggle('opacity-0')
  modal.classList.toggle('pointer-events-none')
  document.body.classList.toggle('modal-active')
  activeModal = activeModal ? null : target
}

function updateLinkOut (target, link) {
  const modal = document.querySelector(target)
  const continueButton = modal.querySelector('.continue-button');
  continueButton.setAttribute('href', link);
}

module.exports = {
  dismissModal,
  toggleModal,
}



// CONGRESS CONFIRMATION COOKIE & MODAL
// var cookieName = 'hcmbmsch'; // The cookie name
// var cookieLifetime = 7; // Cookie expiry in days

/**
* Should the cookie popup be shown?
*/
// var _shouldShowPopup = function () {
//   if (_getCookie(cookieName)) {
//     return false;
//   } else {
//     return true;
//   }
// };

// Show the cookie popup on load if not previously accepted
// if (_shouldShowPopup()) {
//   var congressModalElem = document.getElementById("congressModal");

//   congressModalElem.classList.toggle('opacity-0');
//   congressModalElem.classList.toggle('pointer-events-none');
//   document.body.classList.toggle('modal-active');
//   activeModal = activeModal ? null : congressModalElem
//   }

// Modal dismiss btn - consent
// var congressModalConsentBtn = document.getElementById("congressModalConsent");
// congressModalConsentBtn.addEventListener('click', function (event) {
//   _setCookie(cookieName, 1, cookieLifetime);
// })